import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { color, media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const Image = styled(GatsbyImage)`
  ${media.sm} {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    object-fit: contain !important;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 1.6;
  color: ${color.text.secondary};
  margin: 0;

  ${media.sm} {
    margin-bottom: 20px;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 30px;

  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
    margin-bottom: 15px;
  }
`;

export const ContentLeft = styled.div`
  max-width: 510px;
`;

export const ContentRight = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: end;

  ${media.sm} {
    justify-content: center;
  }
`;

export const Grid = styled(SharedGrid)`
  > div {
    align-items: center;
  }
`;

export const Inner = styled(Container)`
  position: relative;
`;

export const Wrapper = styled.section`
  position: relative;
  margin-bottom: 100px;

  ${media.md} {
    margin-bottom: 80px;
  }
`;
