import styled from 'styled-components';

import { fontSize, media, color } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const ShapeImage = styled.img`
  position: absolute;
  right: -65px;
  bottom: 111px;
  z-index: -1;

  ${media.xs} {
    max-width: 240px;
  }
`;

export const TriangleImage = styled.img`
  position: absolute;
  top: -8px;
  right: -35px;
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
`;

export const Description = styled.p`
  font-size: ${fontSize.md};
  line-height: 1.4;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 24px;

  ${media.sm} {
    font-size: 18px;
    line-height: 1.56;
    margin-bottom: 20px;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 62px;
  margin-bottom: 24px;

  ${media.sm} {
    font-size: 42px;
    line-height: 1.33;
    margin-bottom: 20px;
  }
`;

export const StyledButton = styled(Button)`
  span {
    font-size: ${fontSize.sm} !important;

    ${media.sm} {
      font-size: ${fontSize.xs} !important;
    }
  }

  ${media.md} {
    margin-bottom: 60px;
  }
`;

export const Subtitle = styled.h2`
  font-size: 40px;
  color: ${color.text.secondary};
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 36px;

  ${media.sm} {
    font-size: 32px;
    margin-bottom: 20px;
  }
`;

export const Label = styled.div`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.secondary};
  letter-spacing: 0.08em;
  margin-bottom: 10px;
  font-weight: 500;
  text-transform: uppercase;

  ${media.sm} {
    font-size: ${fontSize.xs};
  }
`;

export const Content = styled.div`
  display: flex;

  ${media.md} {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  max-width: 590px;

  ${media.md} {
    max-width: 100%;
  }
`;

export const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ${media.md} {
    justify-content: center;
  }
`;

export const Inner = styled(Container)`
  position: relative;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-top: 150px;
  margin-bottom: 205px;

  ${media.md} {
    margin-bottom: 80px;
  }
`;

export const AnimationWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  ${media.md} {
    justify-content: center;
    margin-bottom: 50px;
  }

  ${media.xs} {
    margin-bottom: 20px;
  }

  .illustration-wrapper {
    position: relative;
    height: 311px;
    width: 506px;

    ${media.sm} {
      width: 100%;
    }

    ${media.xs} {
      height: 260px;
      width: 320px;
    }
  }

  img {
    position: absolute;
  }

  .illustration-card {
    width: 61%;
  }

  .illustration-card-1 {
    top: 13%;
    left: -14%;
  }

  .illustration-card-2 {
    top: -22%;
    left: 23%;
  }

  .illustration-card-3 {
    top: 65%;
    left: 13.3%;
  }

  .illustration-card-4 {
    top: 14.5%;
    right: -14%;
  }
`;

export const RightContent = styled.div`
  position: relative;
  width: 506px;
  max-width: 100%;

  ${media.lg} {
    width: 400px;
  }

  ${media.md} {
    width: 100%;
  }
`;
