import styled from 'styled-components';

import { media, color } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const LogoWrapper = styled.div`
  ${media.lg} {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
  .gatsby-image-wrapper {
    ${media.lg} {
      width: 140px !important;

      img {
        object-fit: contain !important;
      }
    }

    ${media.sm} {
      width: 100px !important;
    }
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  max-width: 725px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;

  ${media.md} {
    margin-bottom: 25px;
  }

  ${media.sm} {
    font-size: 32px;
    line-height: 1.38;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  padding-bottom: 110px;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 384px;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(169, 109, 224, 0) 8.33%,
      rgba(111, 120, 222, 0) 8.37%,
      #6f78de 355.21%
    );
  }
`;

export const Inner = styled.div`
  position: relative;
  box-shadow: 0 4px 40px rgba(44, 41, 93, 0.1);
  border-radius: 10px;
  padding: 40px 100px;
  background-color: ${color.text.tertiary};
  z-index: 1;

  ${media.lg} {
    padding: 40px;
  }

  ${media.xs} {
    padding: 20px;
  }
`;

export const Container = styled(SharedContainer)``;

export const SliderWrapper = styled.div`
  display: none;
  overflow: hidden;

  ${media.xs} {
    display: block;
  }

  ${LogoWrapper} {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 60px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  ${media.md} {
    justify-content: center;
  }

  ${media.xs} {
    display: none;
  }
`;
