import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import {
  Wrapper,
  Inner,
  Content,
  Left,
  Right,
  Label,
  StyledTitle,
  Subtitle,
  Description,
  StyledButton,
  BackgroundImageWrapper,
  TriangleImage,
  ShapeImage,
  AnimationWrapper,
  RightContent,
} from './hero.styles';
import IllustrationCard1Url from './images/illustration-card-1.url.svg';
import IllustrationCard2Url from './images/illustration-card-2.url.svg';
import IllustrationCard3Url from './images/illustration-card-3.url.svg';
import IllustrationCard4Url from './images/illustration-card-4.url.svg';
import ShapeSvgUrl from './images/shape.url.svg';
import TriangleSvgUrl from './images/triangle.url.svg';

export const Hero = (props) => {
  const { label, title, subtitle, description, buttonText, buttonUrl } = props;

  // Animations
  const [animationContainer, animationContainerView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const motionEase = [0.25, 0.1, 0.25, 1];
  const duration = 0.5;

  const transitionAction = {
    delay: 1,
    duration,
    ease: motionEase,
  };

  const variantsPositionY = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      opacity: 1,
      y: custom,
    }),
  };

  const variantsPositionX = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      opacity: 1,
      x: custom,
    }),
  };

  return (
    <Wrapper>
      <BackgroundImageWrapper>
        <TriangleImage src={TriangleSvgUrl} aria-hidden />
      </BackgroundImageWrapper>
      <Inner>
        <Content>
          <Left>
            <Label>{label}</Label>
            <StyledTitle as="h1" size="xxl">
              {title}
            </StyledTitle>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
            <Description>{description}</Description>
            {buttonUrl && buttonText && (
              <StyledButton component={Link} theme="fill" to={buttonUrl}>
                {buttonText}
              </StyledButton>
            )}
          </Left>
          <Right>
            <RightContent>
              <ShapeImage src={ShapeSvgUrl} aria-hidden />
              <AnimationWrapper ref={animationContainer}>
                <motion.div
                  className="illustration-wrapper"
                  initial="hidden"
                  animate={animationContainerView && 'visible'}
                >
                  <motion.img
                    src={IllustrationCard1Url}
                    className="illustration-card illustration-card-1"
                    variants={variantsPositionX}
                    custom={70}
                    transition={transitionAction}
                  />
                  <motion.img
                    src={IllustrationCard2Url}
                    className="illustration-card illustration-card-2"
                    variants={variantsPositionY}
                    custom={70}
                    transition={transitionAction}
                  />
                  <motion.img
                    src={IllustrationCard3Url}
                    className="illustration-card illustration-card-3"
                    variants={variantsPositionY}
                    custom={-70}
                    transition={transitionAction}
                  />
                  <motion.img
                    src={IllustrationCard4Url}
                    className="illustration-card illustration-card-4"
                    variants={variantsPositionX}
                    custom={-70}
                    transition={transitionAction}
                  />
                </motion.div>
              </AnimationWrapper>
            </RightContent>
          </Right>
        </Content>
      </Inner>
    </Wrapper>
  );
};
