import GatsbyImage from 'gatsby-image';
import * as React from 'react';
import Slider from 'react-slick';

import { getFluidImage, getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Inner,
  Container,
  StyledTitle,
  LogoWrapper,
  ContentWrapper,
  SliderWrapper,
} from './logos.styles';

export const Logos = ({ title, items }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: false,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 413,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const logoItems = items.map(({ id, altText, localFile }) => {
    const fluid = getFluidImage(localFile);
    return (
      <LogoWrapper key={id}>
        <GatsbyImage
          style={{
            width: fluid.presentationWidth / 2,
            height: fluid.presentationHeight / 2,
          }}
          fluid={fluid}
          alt={altText}
          key={id}
        />
      </LogoWrapper>
    );
  });

  return (
    <Wrapper>
      <Container>
        <Inner>
          <StyledTitle
            as="h2"
            size="xl"
            dangerouslySetInnerHTML={{
              __html: getTextWithoutParagraph(title),
            }}
          />
          <ContentWrapper>{logoItems}</ContentWrapper>
          <SliderWrapper>
            <Slider {...settings}>{logoItems}</Slider>
          </SliderWrapper>
        </Inner>
      </Container>
    </Wrapper>
  );
};
