import { graphql } from 'gatsby';
import React from 'react';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Actually } from 'components/pages/get-started/actually/actually';
import { Audience } from 'components/pages/get-started/audience/audience';
import { Hero } from 'components/pages/get-started/hero';
import { Logos } from 'components/pages/get-started/logos/logos';
import { Mention } from 'components/pages/get-started/mention';
import { Rewards } from 'components/pages/get-started/rewards/rewards';
import { buildPageMetadata } from 'utils/data-builders';

const GetStarted = (props) => {
  const {
    data: { wpPage },
  } = props;
  const { acf } = wpPage;

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      {acf.showHeader !== 'false' && <Header withLogoAndRightButtons />}
      <Hero
        label={acf.getStartedHero.textAboveTitle}
        title={acf.getStartedHero.title}
        subtitle={acf.getStartedHero.subtitle}
        description={acf.getStartedHero.description}
        buttonText={acf.getStartedHero.buttonText}
        buttonUrl={acf.getStartedHero.buttonUrl}
      />
      <Rewards
        title={acf.section1.title}
        description={acf.section1.text}
        image={acf.section1.image}
      />
      <Actually
        title={acf.section2.title}
        description={acf.section2.text}
        image={acf.section2.image}
      />
      <Audience
        title={acf.section3.title}
        description={acf.section3.text}
        image={acf.section3.image}
      />
      <Mention
        title={acf.section4.title}
        description={acf.section4.text}
        image={acf.section4.image}
      />
      <Logos title={acf.titleAndLogos.title} items={acf.titleAndLogos.images} />
      {acf.showFooter !== 'false' && <Footer theme="light" withoutBorderTop withoutTopSection />}
    </Layout>
  );
};

export default GetStarted;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      content
      acf {
        getStartedHero {
          textAboveTitle
          title
          subtitle
          description
          buttonText
          buttonUrl
        }
        section1 {
          title
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 488)
              }
            }
          }
        }
        section2 {
          title
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 488)
              }
            }
          }
        }
        section3 {
          title
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 488)
              }
            }
          }
        }
        section4 {
          title
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 488)
              }
            }
          }
        }
        titleAndLogos {
          title
          images {
            id
            altText
            localFile {
              childImageSharp {
                fluid {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        showHeader
        showFooter
      }
    }
  }
`;
