import { getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { GridCell } from 'components/shared/grid';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Inner,
  Grid,
  ContentLeft,
  ContentRight,
  StyledTitle,
  Description,
  Image,
} from './audience.styles';

export const Audience = ({ title, description, image }) => (
  <Wrapper id="party-sales">
    <Inner>
      <Grid
        columns={2}
        gap={20}
        media={{
          sm: { columns: 1, gap: 0 },
        }}
      >
        <GridCell>
          <ContentLeft>
            <StyledTitle
              as="h2"
              size="xl"
              dangerouslySetInnerHTML={{
                __html: getTextWithoutParagraph(title),
              }}
            />
            <Description
              dangerouslySetInnerHTML={{
                __html: getTextWithoutParagraph(description),
              }}
            />
          </ContentLeft>
        </GridCell>
        <GridCell>
          <ContentRight>
            <Image image={getImage(image.localFile.childImageSharp)} alt={image.altText} />
          </ContentRight>
        </GridCell>
      </Grid>
    </Inner>
  </Wrapper>
);
